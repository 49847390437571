@import 'mantine';
.overlay {
  position: absolute;
  background-color: #ffffcc44;
  border: 1px dashed #ddddbb;
  pointer-events: none;
  z-index: 1;
  text-align: left;
}

.label {
  background-color: #ff0;
  pointer-events: all;
  display: inline-flex;
  align-items: center;
  padding: rem(2) rem(12);
  font-size: rem(12);
  color: black;
  opacity: 0.8;
  font-weight: normal;
  text-transform: none;
  font-family: var(--font-studio-fiexen-sans);

  &:hover {
    text-decoration: none;
    opacity: 1;
  }

  svg {
    margin-left: 8px;
  }
}
