@import 'mantine';
.useCasesSection {
  position: relative;
  color: var(--mantine-color-gray-0);
}

.headerImage {
  object-fit: cover;
}

.useCaseTextContainer {
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: flex-end;
  min-height: rem(639);
  padding: rem(16) 0 rem(16);

  background: linear-gradient(
    96.7deg,
    rgba(8, 13, 29, 0.84) 39.55%,
    rgba(8, 13, 29, 0.42) 85.79%
  );

  @include larger-than($mantine-breakpoint-desktop-sm) {
    align-items: flex-end;
    min-height: rem(800);
    padding: rem(80);
    background: linear-gradient(
      68.9deg,
      rgba(8, 13, 29, 0.7) 31.44%,
      rgba(8, 13, 29, 0.2) 58.04%
    );
  }
}

.viewAll {
  position: absolute;
  bottom: rem(16);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    top: rem(80);
    right: rem(80);
    bottom: auto;
  }
}

.progressBarContainer {
  display: flex;
  align-items: center;
  gap: rem(16);
  margin-bottom: rem(32);
  color: var(--mantine-color-yellow-0);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    margin-bottom: 0;
    position: absolute;
    top: rem(80);
    height: calc(100% - rem(168));
    flex-direction: column;
    position: absolute;
  }
}

.progressBar {
  height: 1px;
  background-color: rgba(184, 190, 214, 1);
  flex: 1;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    width: 1px;
    position: relative;
    left: rem(-48);
  }
}

.progressIndicator {
  background-color: var(--mantine-color-yellow-0);
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    transform: scaleY(0);
    transform-origin: top;
    position: absolute;
    top: 0;
    height: 100%;
    bottom: 0;
  }

  &.full {
    transform: scaleX(1);
    transition: transform 15s linear;

    @include larger-than($mantine-breakpoint-desktop-sm) {
      transform: scaleY(1);
    }
  }

  @include larger-than($mantine-breakpoint-desktop-sm) {
    width: 1px;
  }
}

.skipButton {
  cursor: pointer;
  @include larger-than($mantine-breakpoint-desktop-sm) {
    position: relative;
    left: rem(-48);
    display: block;
  }

  & > svg {
    display: block;
  }
}
