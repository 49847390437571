@import 'mantine';
.newsEventsBlock {
  background: var(--mantine-color-blue-5);
  background: linear-gradient(
      25.05deg,
      rgba(8, 13, 29, 0.7) 14.41%,
      rgba(8, 13, 29, 0.35) 83.51%
    ),
    linear-gradient(
      0deg,
      var(--mantine-color-blue-5),
      var(--mantine-color-blue-5)
    );
  color: var(--mantine-color-gray-0);
  padding-block: rem(40);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    padding-block: rem(120);
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newsImage {
  width: 100%;
  aspect-ratio: 19 / 11;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
}

.newsImageWrapper {
  aspect-ratio: 19 / 11;
  overflow: hidden;
  margin-bottom: rem(24);
}

.newsItemLink {
  display: block;
  margin-block-end: rem(60);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    margin-block-end: rem(40);
  }
}

.newsItemLink:hover {
  & .newsImage {
    transform: scale(1.05);
  }
}

.eventLink {
  display: flex;
  align-items: center;
  gap: rem(16);
  padding-block: rem(16);
  border-top: 1px solid var(--mantine-color-gray-8);
}

.eventImage {
  width: rem(60);
  height: rem(60);
  object-fit: cover;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    width: rem(80);
    height: rem(80);
  }
}

.eventImageWrapper {
  width: rem(60);
  height: rem(60);
  border-radius: rem(40);
  overflow: hidden;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    width: rem(80);
    height: rem(80);
  }
}

.eventTitleAndDate {
  display: flex;
  flex-direction: column;
  gap: rem(4);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    flex-direction: row;
    align-items: center;
    gap: rem(8);
    flex: 1;
  }
}

.eventTitle {
  flex: 1;
}
